import React from 'react'
import s from '../Test/Test.module.scss'
import {Title} from '../../components/Title/Title'
import Question from '../../components/Question/Question'
import {AppStateType, TypedDispatch} from '../../redux/redux-store'
import {useDispatch, useSelector} from 'react-redux'
import {FieldValues, SubmitHandler, useForm} from 'react-hook-form'
import {InputSubmitButton} from '../../components/Button/InputSubmitButton'
import {tests} from '../../utils/Tests'
import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {sendForm} from '../../redux/test-reducer'

const Wayne = () => {
    const dispatch = useDispatch<TypedDispatch>()
    const id = useSelector((state:AppStateType) => state.test.id)

    const schema = Yup.object().shape({
        0: Yup.object().shape({
            answer: Yup.string().matches(/^[0-9]+$/),
            addition: Yup.array()
                .when('answer', {
                    is: '1',
                    then: Yup.array().min(1)
                })
        }),
        1: Yup.object({
            answer: Yup.string().matches(/^[0-9]+$/),
            addition: Yup.array()
                .when('answer', {
                    is: '1',
                    then: Yup.array().min(1)
                })
        }),
        2: Yup.object({
            answer: Yup.string().matches(/^[0-9]+$/),
            addition: Yup.array()
                .when('answer', {
                    is: '1',
                    then: Yup.array().min(1)
                })
        }),
        3: Yup.object({
            answer: Yup.string().matches(/^[0-9]+$/),
            addition: Yup.array()
                .when('answer', {
                    is: '1',
                    then: Yup.array().min(1)
                })
        }),
        4: Yup.object({
            answer: Yup.string().matches(/^[0-9]+$/),
        }),
        5: Yup.object({
            answer: Yup.string().matches(/^[0-9]+$/),
            addition: Yup.array()
                .when('answer', {
                    is: '1',
                    then: Yup.array().min(1)
                })
        }),
        6: Yup.object({
            answer: Yup.string().matches(/^[0-9]+$/),
        }),
        7: Yup.object({
            answer: Yup.string().matches(/^[0-9]+$/),
            addition: Yup.array()
                .when('answer', {
                    is: '1',
                    then: Yup.array().min(1)
                })
        }),
        8: Yup.object({
            answer: Yup.string().matches(/^[0-9]+$/),
            addition: Yup.array()
                .when('answer', {
                    is: '1',
                    then: Yup.array().min(1)
                })
        }),
        9: Yup.object({
            answer: Yup.string().matches(/^[0-9]+$/),
        }),
        10: Yup.object({
            answer: Yup.string().matches(/^[0-9]+$/),
            addition: Yup.array()
                .when('answer', {
                    is: '1',
                    then: Yup.array().min(1)
                })
        }),
    });

    const {register, formState: {errors}, handleSubmit, clearErrors, reset, setValue} = useForm<FieldValues>({ resolver: yupResolver(schema)})

    const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
        dispatch(sendForm(id,
            Object.values(data).map(item => {
            return {
                answer: item.answer === '1',
                addition: item.addition
            }
        }))).then(() => reset())
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Title className={s.title}>
                {tests.Wayne.name}
            </Title>
            {
                tests.Wayne.questions.map((item, index) => {
                    return (
                        <Question clearErrors={clearErrors} errors={errors} setValue={setValue} register={register} key={index} question={item} index={index} />
                    )
                })
            }

            <InputSubmitButton>
                Ввести
            </InputSubmitButton>
        </form>
    )
}

export default Wayne
