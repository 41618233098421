import React, {useEffect, useState} from 'react'
import './App.scss'
import './styles/style.scss'
import Test from './pages/Test/Test'
import {useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {AppStateType, TypedDispatch} from './redux/redux-store'
import {getForm} from './redux/test-reducer'
import Preloader from './components/Preloader/Preloader'
import Error from './pages/Error/Error'
import Wayne from './pages/Wayne/Wayne'

const App = () => {
    const location = useLocation()
    const dispatch = useDispatch<TypedDispatch>()
    const isFetch = useSelector((state:AppStateType) => state.test.isFetch)
    const structure = useSelector((state:AppStateType) => state.test.structure)
    const notExist = useSelector((state:AppStateType) => state.test.notExist)
    const isSuccess = useSelector((state:AppStateType) => state.test.isSuccess)
    const isPassed = useSelector((state:AppStateType) => state.test.isPassed)

    const [notFound, setNotFound] = useState(false)

    useEffect(() => {
        const url = new URLSearchParams(location.search)
        const id = url.get('id')

        if(id) dispatch(getForm(id))
        else setNotFound(true)

    }, [])

    if(notFound || notExist) return <Error>Тест не найден</Error>
    if(isSuccess) return <Error>Тест успешно пройден</Error>
    if(isPassed) return <Error>Данный тест уже был пройден</Error>
    if(isFetch || structure === '') return <Preloader />

    return (
        <div className='outer'>
            <div className='container'>
                {
                    structure === 'Wayne'
                    ? <Wayne />
                    : <Test />
                }
            </div>
        </div>
    )
}

export default App
