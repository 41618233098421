export const tests = {
    Wayne: {
        structure: 'Wayne',
        name: 'Шкала Вейна',
        questions: [
            {
                name: '1. Отмечаете ли Вы (при любом волнении) склонность к:',
                variants: [
                    {name: 'Нет'},
                    {
                        name: 'Да',
                        variants: [
                            {name: 'Покраснение лица'},
                            {name: 'Побледнение лица'},
                        ]
                    }
                ]
            },
            {
                name: '2. Бывает ли у Вас онемение или похолодание:',
                variants: [
                    {name: 'Нет'},
                    {
                        name: 'Да',
                        variants: [
                            {name: 'Пальцев кистей, стоп'},
                            {name: 'Целиком кистей, стоп'},
                        ]
                    }
                ]
            },
            {
                name: '3. Бывает ли у Вас изменение окраски (побледнение, покраснение, синюшность):',
                variants: [
                    {name: 'Нет'},
                    {
                        name: 'Да',
                        variants: [
                            {name: 'Пальцев кистей, стоп'},
                            {name: 'Целиком кистей, стоп'},
                        ]
                    }
                ]
            },
            {
                name: '4. Отмечаете ли Вы повышенную потливость',
                variants: [
                    {name: 'Нет'},
                    {
                        name: 'Да',
                        variants: [
                            {name: 'Постоянная'},
                            {name: 'При волнении'},
                        ]
                    }
                ]
            },
            {
                name: '5. Бывают ли у Вас часто ощущения "замирания", "остановки сердца"',
                variants: [
                    {name: 'Нет'},
                    {name: 'Да',}
                ]
            },
            {
                name: '6. Бывают ли у Вас часто ощущения затруднения дыхания, чувство "нехватки" воздуха, учащенное дыхание в душном помещении)',
                variants: [
                    {name: 'Нет'},
                    {
                        name: 'Да',
                        variants: [
                            {name: 'При волнении'},
                            {name: 'В душном помещении'},
                        ]
                    }
                ]
            },
            {
                name: '7. Характерны ли для Вас нарушения со стороны желудочно-кишечного тракта: склонность к запорам, поносам, "вздутию", боли в животе',
                variants: [
                    {name: 'Нет'},
                    {name: 'Да',}
                ]
            },
            {
                name: '8. Бывают ли у Вас обмороки (внезапная потеря сознания или чувство,что можете его потерять)',
                variants: [
                    {name: 'Нет'},
                    {
                        name: 'Да',
                        variants: [
                            {name: 'При волнении'},
                            {name: 'В душном помещении'},
                            {name: 'При длительном пребывании в вертикальном положении'},
                        ]
                    }
                ]
            },
            {
                name: '9. Бывают ли у Вас приступообразные головные боли',
                variants: [
                    {name: 'Нет'},
                    {
                        name: 'Да',
                        variants: [
                            {name: 'Только половина головы'},
                            {name: 'Вся голова'},
                            {name: 'Сжимающие'},
                            {name: 'Пульсирующие'},
                        ]
                    }
                ]
            },
            {
                name: '10. Отмечаете ли Вы в настоящее время снижение работоспособности, быструю утомляемость?',
                variants: [
                    {name: 'Нет'},
                    {name: 'Да',}
                ]
            },
            {
                name: '11. Отмечаете ли Вы нарушение сна?',
                variants: [
                    {name: 'Нет'},
                    {
                        name: 'Да',
                        variants: [
                            {name: 'Да, трудно заснуть'},
                            {name: 'Поверхностный, неглубокий сон с частыми пробуждениями'},
                            {name: 'Чувство невыспанности, усталости при пробуждении утром'},
                        ]
                    }
                ]
            },
        ],
    },
    SpielbergA: {
        structure: 'SpielbergA',
        name: 'Шкала Спилбергера. Реактивная тревожность.',
        questions: [
            {
                name: '1. Я спокоен',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '2. Мне ничто не угрожает',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '3. Я нахожусь в напряжении',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '4. Я испытываю сожаление',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '5. Я чувствую себя свободно',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '6. Я расстроен',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '7. Меня волнуют возможные неудачи',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '8. Я чувствую себя отдохнувшим',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '9. Я встревожен',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '10. Я испытываю чувство внутреннего удовлетворения',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '11.  Я уверен в себе',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '12.  Я нервничаю',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '13. Я не нахожу себе места',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '14. Я взвинчен',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '15. Я не чувствую скованности, напряжения',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '16. Я доволен',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '17. Я озабочен',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '18. Я слишком возбужден и мне не по себе',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '19. Мне радостно',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
            {
                name: '20. Мне приятно',
                variants: [
                    {name: 'Нет, это не так'},
                    {name: 'Пожалуй, так'},
                    {name: 'Верно'},
                    {name: 'Совершенно верно'},
                ]
            },
        ]
    },
    SpielbergB: {
        structure: 'SpielbergB',
        name: 'Шкала Спилбергера. Личностная тревожность.',
        questions: [
            {
                name: '21. Я испытываю удовольствие',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '22. Я очень быстро устаю',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '23. Я легко могу заплакать',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '24. Я хотел бы быть таким же счастливым как другие',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '25. Нередко я проигрываю из-за того, что недостаточно быстро принимаю решения',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '26. Обычно я чувствую себя бодрым',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '27. Я спокоен, хладнокровен и собран',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '28. Меня тревожат возможные трудности',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '29. Я слишком переживаю из-за пустяков',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '30. Я бываю вполне счастлив',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '31. Я все принимаю близко к сердцу',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '32. Мне не хватает уверенности в себе',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '33. Я обычно чувствую себя в безопасности',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '34. Я стараюсь избегать критических ситуаций и трудностей',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '35. У меня бывает хандра',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '36. Я бываю удовлетворен',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '37. Всякие пустяки отвлекают и волнуют меня',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '38. Я так сильно переживаю свои разочарования, что потом долго не могу о них забыть',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '39. Я уравновешенный человек',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
            {
                name: '40. Меня охватывает сильное беспокойство, когда я думаю о своих делах и заботах',
                variants: [
                    {name: 'Никогда'},
                    {name: 'Почти никогда'},
                    {name: 'Часто'},
                    {name: 'Почти всегда'},
                ]
            },
        ]
    },
    HADS: {
        structure: 'HADS',
        name: 'Госпитальная шкала (HADS)',
        questions: [
            {
                name: '1. Я испытываю напряжение, мне не по себе',
                variants: [
                    {name: 'Всё время'},
                    {name: 'Часто'},
                    {name: 'Время от времени, иногда'},
                    {name: 'Совсем не испытываю'},
                ]
            },
            {
                name: '2. Я испытываю страх, кажется, будто что-то ужасное может вот-вот случиться',
                variants: [
                    {name: 'Определенно это так, и страх очень сильный'},
                    {name: 'Да, это так, но страх не очень сильный '},
                    {name: 'Иногда, но это меня не беспокоит'},
                    {name: 'Совсем не испытываю'},
                ]
            },
            {
                name: '3. Беспокойные мысли крутятся у меня в голове',
                variants: [
                    {name: 'Постоянно'},
                    {name: 'Большую часть времени'},
                    {name: 'Время от времени, не так часто'},
                    {name: 'Только иногда'},
                ]
            },
            {
                name: '4. Я могу легко сесть и расслабиться',
                variants: [
                    {name: 'Определенно, это так'},
                    {name: 'Наверное, это так'},
                    {name: 'Лишь изредка это так'},
                    {name: 'Совсем не могу'},
                ]
            },
            {
                name: '5. Я испытываю внутреннее напряжение или дрожь',
                variants: [
                    {name: 'Совсем не испытываю'},
                    {name: 'Иногда'},
                    {name: 'Часто'},
                    {name: 'Очень часто'},
                ]
            },
            {
                name: '6. Я испытываю неусидчивость, словно мне постоянно нужно двигаться',
                variants: [
                    {name: 'Определенно, это так'},
                    {name: 'Наверное, это так'},
                    {name: 'Лишь в некоторой степени это так'},
                    {name: 'Совсем не испытываю'},
                ]
            },
            {
                name: '7. У меня бывает внезапное чувство паники',
                variants: [
                    {name: 'Действительно, очень часто'},
                    {name: 'Довольно часто'},
                    {name: 'Не так уж часто'},
                    {name: 'Совсем не бывает'},
                ]
            },
            {
                name: '8. То, что приносило мне большое удовольствие, и сейчас вызывает такое же чувство',
                variants: [
                    {name: 'Определённо, это так'},
                    {name: 'Наверное, это так'},
                    {name: 'Лишь в очень малой степени это так'},
                    {name: 'Это совсем не так'},
                ]
            },
            {
                name: '9. Я способен рассмеяться и увидеть в том или ином событии смешное',
                variants: [
                    {name: 'Определённо, это так'},
                    {name: 'Наверное, это так'},
                    {name: 'Лишь в очень малой степени это так'},
                    {name: 'Совсем не способен'},
                ]
            },
            {
                name: '10. Я испытываю бодрость',
                variants: [
                    {name: 'Совсем не испытываю'},
                    {name: 'Очень редко'},
                    {name: 'Иногда'},
                    {name: 'Практически всё время'},
                ]
            },
            {
                name: '11. Мне кажется, что я стал все делать очень медленно',
                variants: [
                    {name: 'Практически всё время'},
                    {name: 'Часто'},
                    {name: 'Иногда'},
                    {name: 'Совсем нет'},
                ]
            },
            {
                name: '12. Я не слежу за своей внешностью',
                variants: [
                    {name: 'Определенно, это так'},
                    {name: 'Я не уделяю этому столько времени скольо нужно'},
                    {name: 'Может быть, я стал меньше уделять этому внимания'},
                    {name: 'Я слежу за собой так же, как и раньше'},
                ]
            },
            {
                name: '13. Я считаю, что мои дела (занятия, увлечения) могут принести мне чувство удовлетворения',
                variants: [
                    {name: 'Точно так же, как и обычно'},
                    {name: 'Да, не в той степени как раньше'},
                    {name: 'Значительно меньше, чем обычно'},
                    {name: 'Совсем не считаю'},
                ]
            },
            {
                name: '14. Я могу получить удовольствие от хорошей книги, радио- или телепрограммы',
                variants: [
                    {name: 'Часто'},
                    {name: 'Иногда'},
                    {name: 'Редко'},
                    {name: 'Очень редко'},
                ]
            },
        ]
    },
    MADRS: {
        structure: 'MADRS',
        name: 'Шкала Монтгомери (MADRS)',
        text: 'В качестве основы для школы была использована шкала оценки психопатологии - стандартная скриниговая шкала для общего выявления основных типов психопатологических расстройств. MARDS состоит из 10 вопросов, каждый из которых оценивается в баллах от О до 6. Чем тяжелее состояние больного, тем больший балл ему присваивается. Состояния, соответствующие 0, 2, 4 и 6 баллам, описаны в шкале, нечетные баллы оставлены для промежуточных состояний. Общий балл по шкале, таким образом, варьируется от 0 до 60 и снижается в процессе облегчения состояния. Шкала не градуирована, оценивается только общий балл.',
        questions: [
            {
                name: '1. Объективные (видимые) признаки подавленности. \nПроявление угнетенности, униния, отчаяния (более выраженных, чем при обычном временном снижении настроения) в речи, в мимике и позе. Оцениваются в соотвествии с глубиной снижения настроения.',
                variants: [
                    {name: '0 = отсутствие'},
                    {name: '1 ='},
                    {name: '2 = выглядит подавленным, но настроение легко улучшается'},
                    {name: '3 = '},
                    {name: '4 = выглядит подавленным и несчастным большую часть времени'},
                    {name: '5 ='},
                    {name: '6 = выглядит крайне подавленным и угнетенным все время'},
                ]
            },
            {
                name: '2. Субъективные признаки подавленности. \nСообщение пациента о депрессивном настроении независимо от того, насколько оно проявляется внешними признаками. Включает упадок духа, угнетенность или чувство беспомощности и безнадежности. Оценивается в соответствии с интенсивностью, продолжительностью и степенью того, насколько, по описанию пациента, сниженное настроение связано с внешними событиями.',
                variants: [
                    {name: '0 = эпизодическая подавленность, связанная с внешними обстоятельствами '},
                    {name: '1 = '},
                    {name: '2 = печальное или подавленное настроение, легко поддающиеся улучшению'},
                    {name: '3 = '},
                    {name: '4 = глубокое чувство угнетенности или уныния; \nнастроение еще подвержено влиянию внешних событий'},
                    {name: '5 = '},
                    {name: '6 = постоянное и неизменное чувство подавленности, отчаяния или угнетенности'},
                ]
            },
            {
                name: '3. Внутреннее напряжение. \nЧувство болезненного дискомфорта, смятения, раздражения, психического напряжения, доходящего до паники, сильного страха или душевной боли.',
                variants: [
                    {name: '0 = спокойное состояние; \nтолько чувство внутреннего напряжения'},
                    {name: '1 = '},
                    {name: '2 = эпизодическое чувство раздражения или болезненного дискомфорта'},
                    {name: '3 = '},
                    {name: '4 = постоянное чувство внутреннего напряжения, периодическая паника, преодолеваемая больным с большим трудом'},
                    {name: '5 = '},
                    {name: '6 = неослабевающий крайне выраженный страх или душевная боль; \nнепреодолимая паника'},
                ]
            },
            {
                name: '4. Недостаточный сон. \nУменьшение продолжительности или глубины сна в сравнении с привычными для пациента характеристиками сна.',
                variants: [
                    {name: '0 = обычный сон'},
                    {name: '1 = '},
                    {name: '2 = незначительно затрудненное засыпание или несколько укороченный, поверхностный или прерывистый сон'},
                    {name: '3 = '},
                    {name: '4 = укороченный сон, не менее 2 часов'},
                    {name: '5 = '},
                    {name: '6 = менее 2-3 часов сна'},
                ]
            },
            {
                name: '5. Снижение аппетита. \nУтрата аппетита. Оценивается в соответствии со степенью утраты желания поесть или усилий заставить себя принять пищу.',
                variants: [
                    {name: '0 = нормальный или повышенный аппетит'},
                    {name: '1 = '},
                    {name: '2 = несколько сниженный аппетит'},
                    {name: '3 = '},
                    {name: '4 = отсутствие аппетита; \nпища не имеет вкуса'},
                    {name: '5 = '},
                    {name: '6 = необходимость принуждения для приема пищи'},
                ]
            },
            {
                name: '6. Нарушение концентрации внимания. \nТрудности собраться с мыслями вплоть до утраты способности сконцентрироваться. Оценивается в соответствии с интенсивностью, частотой и степенью утраты способности концентрировать внимание.',
                variants: [
                    {name: '0 = нет нарушений концентрации'},
                    {name: '1 = '},
                    {name: '2 = эпизодически трудно собраться с мыслями'},
                    {name: '3 = '},
                    {name: '4 = затруднения концентрации и длительного сосредоточения со снижением способности читать или поддерживать разговор'},
                    {name: '5 = '},
                    {name: '6 = утрата способности читать или участвовать в разговоре без значительных усилий'},
                ]
            },
            {
                name: '7. Апатия. \nЗатруднения начать какую-либо деятельность или замедленность начала и выполнения повседневной деятельности.',
                variants: [
                    {name: '0 = отсутствие затруднения начать какую-либо деятельность; \nотсутствие замедленности'},
                    {name: '1 = '},
                    {name: '2 = затруднения начать какую-либо деятельность'},
                    {name: '3 = '},
                    {name: '4 = затруднения начать простую повседневную деятельность, выполнение которой требует дополнительных усилий'},
                    {name: '5 = '},
                    {name: '6 = полная апатия; \nнеспособность выполнить что-либо без посторонней помощи'},
                ]
            },
            {
                name: '8. Утрата способности чувствовать. \nСубъективное ощущение снижения интереса к окружающему или деятельности, обычно доставляющим удовольствие. Снижение способности адекватно эмоционально реагировать на внешние события или людей.',
                variants: [
                    {name: '0 = нормальный интерес к окружающему и людям'},
                    {name: '1 = '},
                    {name: '2 = снижение способности получать удовольствие от того, что обычно интересно'},
                    {name: '3 = '},
                    {name: '4 = утрата интереса к окружающему; \nутрата чувств к друзьям и знакомым'},
                    {name: '5 = '},
                    {name: '6 = ощущение эмоционального паралича, утраты способности испытывать гнев, печаль или удовольствие, полной или даже болезненной утраты чувств к близким и друзьям'},
                ]
            },
            {
                name: '9. Пессимистические мысли. \nИдеи собственной вины, малоценности, самоуничижения, греховности или раскаяния.',
                variants: [
                    {name: '0 = отсутствие пессимистических мыслей'},
                    {name: '1 = '},
                    {name: '2 = эпизодические идеи неудачливости в жизни, самоуничижения или малоценности'},
                    {name: '3 = '},
                    {name: '4 = постоянное самообвинение или конкретные, но еще рациональные идеи виновности или греховности; \nнарастающая пессимистическая оценка будущего'},
                    {name: '5 = '},
                    {name: '6 = бредовые идеи полного краха, раскаяния или неискупимого греха; \nабсурдное и непоколебимое самообвинение'},
                ]
            },
            {
                name: '10. Суицидальные мысли. \nЧувство, что жить больше не стоит, что естественная смерть - желаемый исход; суицидальные мысли и приготовления к самоубийству.',
                variants: [
                    {name: '0 = жизнь приносит удовольствие или воспринимается такой, какая она есть'},
                    {name: '1 = '},
                    {name: '2 = усталость от жизни; \nэпизодические мысли о самоубийстве'},
                    {name: '3 = '},
                    {name: '4 = возможно, лучше умереть; \nсуицидальные мысли становятся привычными, а самоубийство рассматривается как возможный способ решения проблем при отсутствии конкретных суицидальных планов или намерений'},
                    {name: '5 = '},
                    {name: '6 = конкретное планирование совершения самоубийства при первой возможности; \nактивные приготовления к самоубийству'},
                ]
            },
        ]
    },
    AD8: {
        structure: 'AD8',
        name: 'AD8 Опросник для скрининга деменции',
        text: 'Этот инструмент предназначен для определения выраженности когнитивных нарушений по оценке информанта (родственника). При наличии выраженных когнитивных нарушений сбор жалоб и анамнеза у самого пациента затруднен как имеющимися мнестическими и/или речевыми нарушениями, так и снижением/отсутствием критики к своему состоянию. Поэтому информация, получаемая от третьих лиц, становится особенно ценной. В роли информантов могут выступать лица, близко знакомые с пациентом и поддерживающие с ним общение (или осуществляющие уход за ним), желательно в течение последних 5 - 10 лет. Опросник может быть рекомендован в качестве скринингового инструмента, позволяющего выявить наличие когнитивных нарушений или вероятной деменции у пациента. Одного лишь скринингового теста недостаточно для выявления когнитивных расстройств. Однако опросник AD8 достаточно чувствителен к изменениям в когнитивной сфере, связанными со многими распространенными заболеваниями, включая болезнь Альцгеймера, сосудистую деменцию, болезнь диффузных телец Леви и лобно-височную деменцию.',
        questions: [
            {
                name: '1. Проблемы с суждением (например, проблемы с принятием решений, неудачные финансовые действия, проблемы с мышлением)',
                variants: [
                    {name: 'Да, изменения есть'},
                    {name: 'Нет, изменений нет'},
                    {name: 'Затрудняюсь ответить'},
                ]
            },
            {
                name: '2. Снижение интереса к привычным делам/увлечениям',
                variants: [
                    {name: 'Да, изменения есть'},
                    {name: 'Нет, изменений нет'},
                    {name: 'Затрудняюсь ответить'},
                ]
            },
            {
                name: '3. Постоянное повторение одного и того же (вопроса/рассказа/утверждения)',
                variants: [
                    {name: 'Да, изменения есть'},
                    {name: 'Нет, изменений нет'},
                    {name: 'Затрудняюсь ответить'},
                ]
            },
            {
                name: '4. Проблемы с освоением инструментов, устройств или гаджетов (например, видеомагнитофона, компьютера, микроволновой печи, пульта дистанционного управления)',
                variants: [
                    {name: 'Да, изменения есть'},
                    {name: 'Нет, изменений нет'},
                    {name: 'Затрудняюсь ответить'},
                ]
            },
            {
                name: '5. Забывает текущий месяц или год',
                variants: [
                    {name: 'Да, изменения есть'},
                    {name: 'Нет, изменений нет'},
                    {name: 'Затрудняюсь ответить'},
                ]
            },
            {
                name: '6. Проблемы с решением сложных финансовых вопросов (например, использование чековой книжки, выплата налогов, оплата счетов)',
                variants: [
                    {name: 'Да, изменения есть'},
                    {name: 'Нет, изменений нет'},
                    {name: 'Затрудняюсь ответить'},
                ]
            },
            {
                name: '7. Забывает о назначенных встречах',
                variants: [
                    {name: 'Да, изменения есть'},
                    {name: 'Нет, изменений нет'},
                    {name: 'Затрудняюсь ответить'},
                ]
            },
            {
                name: '8. Ежедневные проблемы с мышлением и (или) памятью',
                variants: [
                    {name: 'Да, изменения есть'},
                    {name: 'Нет, изменений нет'},
                    {name: 'Затрудняюсь ответить'},
                ]
            },
        ]
    },
    Arizon: {
        structure: 'Arizon',
        name: 'Аризонский опросник для родственников',
        text: 'Этот инструмент предназначен для определения выраженности когнитивных нарушений по оценке информанта (родственника). Опросник может быть рекомендован в качестве скринингового инструмента, позволяющего выявить наличие когнитивных нарушений или вероятной деменции у пациента. В случае выявления высокой вероятности наличия когнитивного расстройства, рекомендована консультация психиатра или невролога для уточнения диагноза, оценки когнитивного статуса, биполярного аффективного расстройства и других психотических расстройств, наличия коморбидных психических расстройств.',
        questions: [
            {
                name: '1. У близкого вам человека есть проблемы с памятью?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '2. Если это так, стала память хуже, чем несколько лет назад?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '3. Ваш близкий повторяет один и тот же вопрос или высказывает одну и ту же мысль несколько раз в течение дня?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '4. Забывает ли он о назначенных встречах или событиях?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '5. Кладёт ли он вещи в непривычные места чаще 1 раза в месяц?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '6. Подозревает ли близких в том, что они прячут или крадут его вещи, когда не может найти их?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '7. Часто ли он испытывает трудности при попытке вспомнить текущий день недели, месяц, год?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '8. Он испытывает проблему с ориентацией в незнакомом месте?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '9. Усиливается ли рассеянность за пределами дома, в поездках?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '10. Возникают ли проблемы при подсчёте сдачи в магазине?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '11. Есть ли трудности с оплатой счетов, финансовых операций?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '12. Забывает ли он принимать лекарства? Были случаи, когда он не мог вспомнить, принимал ли он уже лекарство?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '13. Есть ли проблемы с управлением автомобилем?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '14. Возникают ли трудности при пользовании бытовыми приборами, телефоном, телевизионным пультом?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '15. Испытывает ли он затруднения, выполняя работу по дому?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '16. Потерял ли он интерес к привычным увлечениям?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '17. Может ли ваш близкий потеряться на знакомой территории (например, рядом с собственным домом)?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '18. Утрачивает ли чувство правильного направления движения?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '19. Случается ли, что ваш близкий не только забывает имена, но и не может вспомнить нужное слово?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '20. Путает ли ваш близкий имена родственников или друзей?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
            {
                name: '21. Есть ли у него проблемы с узнаванием знакомых людей?',
                variants: [
                    {name: 'Да'},
                    {name: 'Нет'},
                ]
            },
        ]
    }
}
