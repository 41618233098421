import React, {FC} from 'react'
import s from './Title.module.scss'
import cl from 'classnames'

export const Title:FC<MyProps> = ({children, className}) => {
    return (
        <h1 className={cl(s.title, className)}>
            {children}
        </h1>
    )
}

type MyProps = {
    children: React.ReactNode | string
    className?: string
}
