import React, {FC} from 'react'
import s from './QuestionName.module.scss'
import cl from 'classnames'

const QuestionName:FC<MyProps> = ({children, className}) => {
    return (
        <div className={cl(s.question, className)}>
           {children}
        </div>
    )
}

export default QuestionName

type MyProps = {
    children: React.ReactNode | string
    className?: string
}
