import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./redux/redux-store";
import {createRoot} from 'react-dom/client'

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <BrowserRouter basename={process.env.PUBLIC_URL} >
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>,
);

reportWebVitals();
