import {ButtonProps} from './ButtonProps'
import {getClasses} from '../../utils/getClasses'
import {useMemo} from 'react'
import styles from './Button.module.scss'

export const useButton = (props: ButtonProps) => {
    const classes = useMemo(() => {
        const conditions:{[index: string]:boolean} = {
            "button": true,
        };
        return getClasses(conditions, styles, props.className)
    }, [props]);

    return {classes}
}
