import styles from './Checkbox.module.scss'
import React, {forwardRef} from 'react'
import {useCheckbox} from './useCheckbox'
import {CheckboxProps} from './CheckboxProps'
import {ReactComponent as Check} from '../../assets/icons/checked.svg'

/**
 * Checkbox component
 */

export const Checkbox = forwardRef((props: CheckboxProps, ref: any) => {
    const {classes, containerClasses, checked, handleCheck, labelStyleClass} = useCheckbox(props)

    return (
        <div onClick={() => handleCheck(checked)} className={containerClasses} style={props.style}>
            <div className={styles['checkbox-hidden-input']}>
                <input ref={ref}
                       type='checkbox'
                       id={props.inputId}
                       checked={checked}
                       name={props.name}
                       aria-checked={checked}
                       disabled={(props.disabled !== undefined && props.disabled)}
                       onChange={(e) => handleCheck(e.target.checked)}
                />
            </div>
            <label className={classes}>
                {checked && <Check />}
            </label>
            <label htmlFor={props.inputId}
                   className={labelStyleClass}>
                {props.label}
            </label>
        </div>
    )
})

