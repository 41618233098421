import React, {FC} from 'react'
import s from './Error.module.scss'

const Error:FC<MyProps> = ({children}) => {
    return (
        <div className={s.error}>
            {children}
        </div>
    )
}

export default Error

type MyProps = {
    children: React.ReactNode | string
}
