import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styles from './Checkbox.module.scss'
import {CheckboxProps} from './CheckboxProps'
import {getClasses} from '../../utils/getClasses'

/**
 * Hook for checkbox
 */
export function useCheckbox(props: CheckboxProps) {
    const [checked, setChecked] = useState<boolean>(props.defaultChecked || false)

    useEffect(() => {
        if(props.defaultChecked !== undefined) {
            setChecked(props.defaultChecked)
        }
    }, [props.defaultChecked])

    const handleCheck = useCallback((checked: boolean) => {
        if (!props.disabled) {
            setChecked(!checked)
            if(props.onChange) {
                props.onChange(!checked)
            }
        }
    }, [props])

    const containerClasses = useMemo(() => {
        const conditions: {[index: string]: boolean} = {
            'checkbox': true,
            'checkbox-disabled': Boolean(props.disabled),
        }
        return getClasses(conditions, styles)
    }, [props])

    const classes = useMemo(() => {
        const conditions: {[index: string]: boolean} = {
            'checkbox-box': true,
            'checkbox-disabled': Boolean(props.disabled),
            'checkbox-checked': checked,
        }
        return getClasses(conditions, styles, props.className)
    }, [props, checked])

    const labelStyleClass = useMemo(() => {
        return getClasses({'checkbox-label': true}, styles, props.labelStyleClass)
    }, [props])

    return {classes, containerClasses, labelStyleClass, checked, handleCheck}
}
