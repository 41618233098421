import React from 'react'
import s from './Test.module.scss'
import {Title} from '../../components/Title/Title'
import Question from '../../components/Question/Question'
import {AppStateType, TypedDispatch} from '../../redux/redux-store'
import {useDispatch, useSelector} from 'react-redux'
import {FieldValues, SubmitHandler, useForm} from 'react-hook-form'
import {InputSubmitButton} from '../../components/Button/InputSubmitButton'
import {tests} from '../../utils/Tests'
import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {sendForm} from '../../redux/test-reducer'
import {TestStructureEnum} from '../../types/Types'

const Test = () => {
    const dispatch = useDispatch<TypedDispatch>()
    const id = useSelector((state:AppStateType) => state.test.id)
    const structure = useSelector((state:AppStateType) => state.test.structure)

    const shape = Object.assign({},
        tests[structure as TestStructureEnum].questions.map((item, index) => {
        return Yup.object().shape({
            answer: Yup.number().min(0)
        })
    }))

    //@ts-ignore
    const schema = Yup.object().shape(shape)
    const {register, formState: {errors}, handleSubmit, clearErrors, reset, setValue} = useForm<FieldValues>({ resolver: yupResolver(schema), mode: 'onChange'})

    const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
        dispatch(sendForm(id,
            Object.values(data).map(item => {
            return item.answer

        }))).then(() => reset())
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Title className={s.title}>
                {tests[structure as TestStructureEnum].name}
            </Title>
            {
                tests[structure as TestStructureEnum].questions.map((item, index) => {
                    return (
                        <Question clearErrors={clearErrors} errors={errors} setValue={setValue} register={register} key={index} question={item} index={index} />
                    )
                })
            }

            <InputSubmitButton>
                Ввести
            </InputSubmitButton>
        </form>
    )
}

export default Test
