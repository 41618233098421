export const baseURL = process.env.REACT_APP_PRODUCTION_URL || window.location.origin + "/";

export enum StatusCodesEnum {
    Success = 200,
    NoContent = 204,
    Unauthorized = 401,
    Forbidden = 403,
    ServerError = 500,
    BadRequest = 400,
    NotFound = 404,
    ServiceUnavailable = 503,
}
