import {Action, AnyAction, applyMiddleware, combineReducers, legacy_createStore as createStore} from 'redux'
import thunkMiddleware, {ThunkAction, ThunkDispatch} from 'redux-thunk'
import testReducer from './test-reducer'

let rootReducer  = combineReducers({
    test: testReducer,
});

type RootReducerType = typeof rootReducer //(globalState: AppStateType) => AppStateType
export type AppStateType = ReturnType<RootReducerType>

let store = createStore(rootReducer , applyMiddleware(thunkMiddleware));

//key - это название action creator, a U - это выводимый тип функции
// type PropertiesTypes= T extends {[key: string]:
export type InferActionsTypes<T> = T extends {[key: string]: (...args: any[]) => infer U } ? U : never
export type BaseThunkType<A extends Action, R = Promise<void>> = ThunkAction<R, AppStateType, unknown, A>
export type TypedDispatch = ThunkDispatch<AppStateType, any, AnyAction>;

if (process.env.NODE_ENV === 'development') {
// @ts-ignore
    window.store = store
}

export default store;
