import React, {forwardRef, useMemo} from 'react'
import {ButtonProps} from './ButtonProps'
import {getAllEvents} from '../../utils/getAllEvents'
import {useButton} from './useButton'

export const InputSubmitButton=forwardRef((props: ButtonProps, ref: any) => {
    const {classes} = useButton(props);

    const events=useMemo(() => {
        return getAllEvents(props)
    },[props]);

    return (
        <input type={'submit'} value={props.children as string} {...events} ref={ref} style={props.style} className={classes} disabled={Boolean(props.disabled)} />
    )
})

