import {baseURL} from './api'
import axios from 'axios'
import {TestStructureEnum, WayneStructure} from '../types/Types'

export type GetFormResponseType = {
    id: string
    structure: TestStructureEnum
}

export type SendFormResponseType = {
    message: string
}

export const testAPI = {
    getForm(id: string) {
        return axios.get<GetFormResponseType>(baseURL + `api/web?id=${id}`)
            .then((response) => response.data)
    },

    sendForm(id: string, answers: WayneStructure[] | number[]) {
        let data = JSON.stringify({
            id: id,
            data: answers
        })

        return axios.post<SendFormResponseType>(baseURL + `api/web?id=${id}`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((response) => response.data)
    },
}
