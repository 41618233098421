import styles from '../RadioGroup.module.scss'
import React, {forwardRef} from 'react'
import {useRadio} from './useRadio'
import {RadioProps} from './RadioProps'

/**
 * Radio component
 */

export const Radio = forwardRef((props: RadioProps, ref: any) => {
    const {classes, labelStyleClass, containerClasses} = useRadio(props)

    return (
        <label htmlFor={props.inputId || props.value} className={containerClasses} style={props.style}>
            <div className={styles['radio-hidden-input']}>
                <input type='radio'
                       id={props.inputId}
                       value={props.value}
                       checked={props.active === props.value}
                       disabled={(props.disabled !== undefined && props.disabled)}
                       aria-checked={props.active === props.value}
                       ref={ref}
                       onChange={props.onChange}
                       onKeyUp={props.onKeyUp}
                />
            </div>
            <div className={`${classes} ${props.active === props.value ? styles['radio-checked'] : ''}`}>
                <div className={styles['radio-dot']}></div>
            </div>
            <label htmlFor={props.inputId || props.value} className={labelStyleClass}>
                {props.label}
            </label>
        </label>
    )
})
