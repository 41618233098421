import React, {FC, useEffect, useState} from 'react'
import s from './Question.module.scss'
import QuestionName from './QuestionName/QuestionName'
import cl from 'classnames'
import {Radio} from '../RadioGroup/Radio/Radio'
import {Checkbox} from '../Checkbox/Checkbox'
import {UseFormClearErrors, UseFormRegister, UseFormSetValue} from 'react-hook-form/dist/types/form'
import {FieldValues} from 'react-hook-form'

const Question: FC<MyProps> = ({question, className, index, register, setValue, errors, clearErrors}) => {
    const [checkedValue, setCheckedValue] = useState<string>('-1')
    const [checkboxValues, setCheckboxValues] = useState<number[]>([])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedValue(e.target.value)
        setValue(String(index), {answer: e.target.value, addition: checkboxValues})
        clearErrors(String(index))
    }

    const handleCheckboxToggle = (checked: boolean, index: number) => {
        if (checked) {
            if (!checkboxValues.includes(index)) {
                setCheckboxValues(array => [...array, index])
            }
        } else {
            setCheckboxValues(checkboxValues.filter((item) => item !== index))
        }
    }

    useEffect(() => {
        const variants = question.variants[1]?.variants
        if(variants && variants.length > 0) {
            if(checkedValue !== '1') {
                setCheckboxValues([])
            }
        }
    }, [checkedValue])
    useEffect(() => {
        setValue(String(index), {answer: checkedValue, addition: checkboxValues})
        clearErrors(String(index))
    }, [checkboxValues])

    return (
        <section className={cl(s.question, className)}>
            <QuestionName className={s.name}>
                {question.name}
                <div className={s.error}>
                    {
                        (errors[index]?.addition || errors[index]?.answer)
                        &&
                        <>
                            Выберите один из вариантов ответов
                        </>
                    }
                </div>
            </QuestionName>

            {
                question.variants.length > 0 &&
                question.variants.map((radio: any, radioIndex: number) => {
                    return (
                        <div key={radioIndex}>
                            <Radio name={String(index) + radio.name}ref={register(String(index)).ref}
                                   onChange={handleChange} active={checkedValue}
                                   inputId={question.name + radio.name} key={radioIndex} value={String(radioIndex)} label={radio.name} />
                            {
                                (radio.variants && radio.variants.length > 0) &&
                                <div className={s.checkboxContainer}>
                                    {
                                        radio.variants.map((checkbox: any, count: number) => {

                                            return (
                                                <Checkbox defaultChecked={checkboxValues.includes(count)} key={count}
                                                          disabled={checkedValue !== String(radioIndex)}
                                                          label={checkbox.name} ref={register(String(index)).ref}
                                                          onChange={(checked) => handleCheckboxToggle(checked, count)}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    )
                })
            }
        </section>
    )
}

export default Question

type MyProps = {
    className?: string
    index: number
    question: {
        name: string,
        variants: {
            name: string,
            variants?: {
                name: string
            }[]
        }[]
    }
    register: UseFormRegister<FieldValues>
    setValue: UseFormSetValue<FieldValues>
    clearErrors: UseFormClearErrors<FieldValues>
    errors?: any
}
