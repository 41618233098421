import {useCallback, useMemo, useState} from 'react'
import styles from '../RadioGroup.module.scss'
import {RadioProps} from './RadioProps'
import {getClasses} from '../../../utils/getClasses'

/**
 * Hook for radio
 */
export function useRadio(props: RadioProps) {

  const containerClasses = useMemo(() => {
    const conditions:{[index: string]:boolean} = {
      "radio": true,
      "radio-disabled": Boolean(props.disabled),
    };
    return getClasses(conditions, styles)
  }, [props.disabled]);

  const classes = useMemo(() => {
    const conditions:{[index: string]:boolean} = {
      "radio-box": true,
      "radio-disabled": Boolean(props.disabled),
    };
    return getClasses(conditions, styles, props.className)
  }, [props.className, props.disabled]);

  const labelStyleClass = useMemo(() => {
    const conditions:{[index: string]:boolean} = {
      'radio-label': true,
      "radio-disabled": Boolean(props.disabled),
    };
    return getClasses(conditions, styles, props.labelStyleClass)
  }, [props.labelStyleClass, props.disabled]);

  return {classes, labelStyleClass, containerClasses}
}
