import {BaseThunkType, InferActionsTypes} from './redux-store'
import {testAPI} from '../api/test-api'
import {TestStructureEnum, WayneStructure} from '../types/Types'
import {StatusCodesEnum} from '../api/api'

export type InitialStateType = typeof initialState
let initialState = {
    isFetch: false as boolean,
    id: '' as string,
    structure: '' as TestStructureEnum | '',
    notExist: false as boolean,
    isSuccess: false as boolean,
    isPassed: false as boolean,
}

const testReducer = (state = initialState, action: TestActionsType):InitialStateType  => {
    switch (action.type) {
        case 'NS/TEST/TEST_DATA_RECEIVED':
            return {
                ...state,
                id: action.payload.id,
                structure: action.payload.structure,
            }
        case 'NS/TEST/TOGGLE_IS_FETCHING':
            return {
                ...state,
                isFetch: action.payload.isFetch
            }
        case 'NS/TEST/SET_NOT_EXIST_ERROR':
            return {
                ...state,
                notExist: action.payload.notExist
            }
        case 'NS/TEST/SET_TEST_PASSED_ERROR':
            return {
                ...state,
                isPassed: action.payload.isPassed
            }
        case 'NS/TEST/SUCCESS_TEST_MESSAGE_RECEIVED':
            return {
                ...state,
                isSuccess: action.payload.isSuccess
            }

        default:
            return state;
    }
}

export type TestActionsType = InferActionsTypes<typeof testActions>

export const testActions = {
    toggleIsFetching: (isFetch: boolean) =>
        ({type: 'NS/TEST/TOGGLE_IS_FETCHING', payload: {isFetch}} as const),
    testFormReceived: (id: string, structure: TestStructureEnum) =>
        ({type: 'NS/TEST/TEST_DATA_RECEIVED', payload: {id, structure}} as const),
    setNotExistError: (notExist: boolean) =>
        ({type: 'NS/TEST/SET_NOT_EXIST_ERROR', payload: {notExist}} as const),
    setTestPassed: (isPassed: boolean) =>
        ({type: 'NS/TEST/SET_TEST_PASSED_ERROR', payload: {isPassed}} as const),
    successTestMessageReceived: (isSuccess: boolean) =>
        ({type: 'NS/TEST/SUCCESS_TEST_MESSAGE_RECEIVED', payload: {isSuccess}} as const),
}


type ThunkType = BaseThunkType<TestActionsType>

export const getForm = (id: string): ThunkType => {
    return async (dispatch) => {
        dispatch(testActions.toggleIsFetching(true))
        try {
            let data = await testAPI.getForm(id)
            console.log('getForm', data)
            dispatch(testActions.testFormReceived(data.id, data.structure))

            dispatch(testActions.toggleIsFetching(false))
        }
        catch (e: any) {
            console.error('getForm', e.response);
            if(e.response.status === StatusCodesEnum.NotFound) {
                dispatch(testActions.setNotExistError(true))
            }
            if(e.response.status === StatusCodesEnum.BadRequest) {
                dispatch(testActions.setTestPassed(true))
            }
            dispatch(testActions.toggleIsFetching(false))
        }
    }
}
export const sendForm = (id: string, answers: WayneStructure[] | number[]): ThunkType => {
    return async (dispatch) => {
        dispatch(testActions.toggleIsFetching(true))
        try {
            let data = await testAPI.sendForm(id, answers)
            console.log('sendForm', data)

            dispatch(testActions.successTestMessageReceived(true))

            dispatch(testActions.toggleIsFetching(false))
        }
        catch (e: any) {
            console.error('sendForm', e.response);
            if(e.response.status === StatusCodesEnum.BadRequest) {
                dispatch(testActions.setNotExistError(true))
            }
            dispatch(testActions.toggleIsFetching(false))
        }
    }
}

export default testReducer
